<template>
	<div class="trailer-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'trailer-edit' ? $t("titles.editTrailer") : $t("titles.createTrailer")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0" v-if="mayEdit">
				<small-button :text="$route.name === 'trailer-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="hasFetchedData === false" />
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.trailerDetails")}}</div>
				<field-input :label='$t("labels.serial")' v-model='serialNumber' :error="serialNumberError" />
				<field-input :label='$t("labels.numberPlate")' v-model='numberPlate' :error="numberPlateError" />
			</form-container>
		</template>
	</div>
</template>

<script>

	import {mapState} from "vuex";
	import permissions from "@/permissions";
	import isGranted from "@/mixin/isGranted";
	import SmallButton from "@/components/buttons/SmallButton";
	import Spinner from "@/components/loaders/Spinner";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	
	export default {
		name: "AddEditTrailer",
		components: {FieldInput, FormContainer, Spinner, SmallButton},
		mixins: [isGranted],
		data(){
			return {
				hasFetchedData: false,
				responseError: '',
				loader: false,
				serialNumber: '',
				serialNumberError: '',
				numberPlate: '',
				numberPlateError: '',
			}
		},
		computed: {
			...mapState('concrete', {
				trailers: 'trailers'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.trailers[this.$route.params.id] !== 'undefined') ? this.trailers[this.$route.params.id] : false ;
			},
			hasError(){
				return this.serialNumberError !== '' || this.numberPlateError !== '';
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.TRAILER.UPDATE) )
					return true;
				
				return (this.viewingEntity !== false) ? this.isGranted(permissions.TRAILER.UPDATE,this.viewingEntity) : false;
			}
		},
		methods: {
			buildPayload(){
				return {
					entity: {
						'@type': 'Trailer'
					},
					method: (this.$route.name === 'trailer-edit') ? 'put' : 'post',
					payload: {
						serialNumber: this.serialNumber,
						numberPlate: this.numberPlate,
					}
				};
			},
			attemptSubmit(){
				if(this.loader) return;
				this.validateInputs();
				if(!this.hasError)
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							if(this.$route.name === 'trailer-add')
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.trailerWasCreatedTitle"),
									text: this.$t("alerts.trailerWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.push({ name: 'entity-list', params: {entityType: 'trailers'} })
									}
								});
							}
							else
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.trailerWasUpdatedTitle"),
									text: this.$t("alerts.trailerWasUpdatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((response) => {
									if (response.isConfirmed) {
										this.$router.push({ name: 'trailer-view', params: {id: result.id} })
									}
								});
							}
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined')
						{
							// @todo Handle specific errors
							if(typeof error.data['violations'] !== 'undefined')
							{
								error.data['violations'].forEach((error) => {
									if(typeof this[error.propertyPath+'Error'] !== 'undefined')
									{
										this[error.propertyPath+'Error'] = this.$t('symfonyErrors.'+error.message.replace(/\./g, ""));
									}
								});
								this.$swal.fire({
									icon: 'error',
									title: this.$t("terms.error"),
									text: this.$t("errors.errorsInFields"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								});
							}
							else
							{
								this.fireGenericError();
							}
						}
						else
						{
							this.fireGenericError();
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				this.serialNumberError = '';
				this.numberPlateError = '';
				if(this.serialNumber === '')
					this.serialNumberError = this.$t("validation.pleaseProvideSerial");
				if(this.numberPlate === '')
					this.numberPlateError = this.$t("validation.pleaseProvideSerial");
			},
			fetchRouteEntity(){
				if(this.routeEntity === false)
				{
					this.hasFetchedData = false;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Trailer'}})
					.then(result => {

						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.hasFetchedData = true;
					},() => {
						this.hasFetchedData = true;
					})
				}
				else
				{
					this.updateLocalValues();
					this.hasFetchedData = true;
				}
			},
			updateLocalValues(){
				this.numberPlate = this.routeEntity.numberPlate;
				this.serialNumber = this.routeEntity.serialNumber;
			}
		},
		mounted()
		{
			if(typeof this.$route.params.id !== 'undefined')
			{
				this.fetchRouteEntity();
			}
			else
			{
				this.hasFetchedData = true;
			}
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
